import { Box,  styled } from "@mui/material";
import { Outlet } from "react-router-dom";

const Wrapper = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  background: theme.palette.background.default,
  display: "flex",
  alignItems: "center",
  overflowX: "hidden",
}));

const Background = styled(Box)(() => ({
  width: "100%",
  minHeight: "100vh",
  position: "absolute",
  zIndex: 0,
}));

export default function AuthLayout() {
  return (
    <Wrapper>
      <Background>
        <img
          src="/images/home-bg.svg"
          alt="background"
          style={{ width: "100%", objectFit: "cover" }}
        />
      </Background>
      <Box sx={{ width: "100%" }}>
        <Outlet />
      </Box>
    </Wrapper>
  );
}
