import ApiInstance from "../utils/instance";

export const GetExpensesByQuery = (query) => {
    return ApiInstance.get('/hrms_expense', {params: query});
}

export const GetExpenseById = (id) => {
    return ApiInstance.get(`/hrms_expense/${id}`);
}

export const CreateExpense = (params) => {
    return ApiInstance.post('/hrms_expense', params);
}

export const UpdateExpense = (id, params) => {
    return ApiInstance.put(`/hrms_expense/${id}`, params);
}

export const DeleteExpense = (ids) => {
    return ApiInstance.delete(`/hrms_expense/${ids}`);
}