import isThis from "lib/is-this";

export const Roles = {
  SuperAdmin: "Super Admin",
  Admin: "Admin",
  Employee: "Employee",
};

export function isRole(user, role) {
  return isThis.isStringsEqual(user?.role, role);
}

export function isSuperAdmin(user) {
  return isThis.isStringsEqual(user?.role, Roles.SuperAdmin);
}
export function isAdmin(user) {
  return isThis.isStringsEqual(user?.role, Roles.Admin);
}
export function isAdminOrSuperAdmin(user) {
  return isAdmin(user) || isSuperAdmin(user);
}
