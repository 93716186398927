import "./App.css";
import "./theme/style.css";
import { useEffect, useMemo } from "react";
import BuildTheme from "./theme";
import store from "./store";
import Login from "./pages/Login";
import { routes } from "./routes/PrivateRoute";
import { Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material";
import AppLayout from "./layouts/AppLayout";
import AuthLayout from "./layouts/AuthLayout";
import { useNavigate } from "react-router-dom";

import { useLocation } from "react-router-dom";
import axios from "axios";
import { SetItem } from "utils/storage";
import { StorageKey } from "constants/constants";
import ApiInstance from "utils/instance";
const PrivateRoutes = () => {
  return routes.map(({ component: Component, ...props }, i) => {
    let element = <Component {...props} />;

    return <Route key={i} {...props} element={element} />;
  });
};

function App() {
  const theme = useMemo(() => BuildTheme("light"), []);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const sessionId = searchParams.get("sessionId");
  useEffect(() => {
    if (sessionId) {
      ApiInstance.post(`/auth/login-with-session`, { sessionId })
        .then(async (res) => {
          console.log(res);

          if (res.status === 200) {
            const { _id, email, firstName, lastName, role, sessionId } =
              res?.data?.data?.profileDetails;
            const { token } = res.data?.data;

            SetItem(StorageKey.TOKEN, token);
            SetItem(
              StorageKey.USER,
              JSON.stringify({
                id: _id,
                email,
                fullname: `${firstName} ${lastName}`,
                role,
                sessionId,
              })
            );
          }
          navigate("/app");
        })
        .catch((err) => console.log(err));
    }
  }, [sessionId]);
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        {/* <BrowserRouter> */}
        <Routes>
          <Route path="/" element={<AuthLayout />}>
            <Route path="/" element={<Login />} />
          </Route>
          <Route path="/app" element={<AppLayout />}>
            {PrivateRoutes()}
          </Route>
        </Routes>
        {/* </BrowserRouter> */}
      </ThemeProvider>
    </Provider>
  );
}

export default App;
