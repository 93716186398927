import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { DefaultSwrOptions, StorageKey } from "../../constants/constants";
import useSWR from "swr";
import { GetSetting, UpdateSetting } from "../../service/setting";
import Breadcrumb from "../../components/Breadcrumb";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  FormLabel,
  Stack,
  TextField,
} from "@mui/material";
import CountrySelect from "../../components/forms/CountrySelect";
import PhoneInput from "../../components/forms/PhoneInput";
import { GetItem } from "utils/storage";
import countries from "../../data/countries.json";
export default function SettingForm() {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const { data: resData } = useSWR(
    "/api/setting",
    () => GetSetting(),
    DefaultSwrOptions
  );

  const defaultCountry = countries[0];

  const formik = useFormik({
    initialValues: {
      email: resData?.data?.data?.email ?? "",
      name: resData?.data?.data?.companyName ?? "",
      addressLine1: resData?.data?.data?.addressLine1 ?? "",
      addressLine2: resData?.data?.data?.addressLine2 ?? "",
      city: resData?.data?.data?.city ?? "",
      country: defaultCountry,
      phone: resData?.data?.data?.phone ?? "",
      leaveLimit: resData?.data?.data?.defaultLeaveLimitPerYear ?? "",
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => handleSubmit(values),
  });

  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current && resData?.data?.data?._id) {
      const country = countries.find(
        (e) => e.name === resData?.data?.data?.country
      );

      formik.setValues({
        email: resData?.data?.data?.email ?? "",
        name: resData?.data?.data?.companyName ?? "",
        addressLine1: resData?.data?.data?.addressLine1 ?? "",
        addressLine2: resData?.data?.data?.addressLine2 ?? "",
        city: resData?.data?.data?.city ?? "",
        country: country || countries[0],
        phone: resData?.data?.data?.phone ?? "",
        leaveLimit: resData?.data?.data?.defaultLeaveLimitPerYear ?? "",
      });
      mounted.current = true;
    }
  }, [resData?.data?.data]);

  const handleSubmit = async (values) => {
    setLoadingSubmit(true);

    const user = await GetItem(StorageKey.USER);

    return UpdateSetting({
      ...{
        companyName: values.name,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        city: values.city,
        country: values.country.name,
        email: values.email,
        phone: values.phone,
        userId: user.id,
        defaultLeaveLimitPerYear: +values.leaveLimit,
      },
    }).then((res) => {
      if (res.status === 200) {
        setSuccess("Successfully Updated Data");
      } else {
        setError(res.data);
      }
      setLoadingSubmit(false);
    });
  };


  return (
    <>
      <Breadcrumb
        title="Update Setting"
        items={[
          { to: "/app", title: "Dashboard" },
          { title: "Update Setting" },
        ]}
      />
      <Box sx={{ width: { xs: "100%", lg: "50%" } }}>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={3}>
            <Card>
              <CardContent>
                {error && (
                  <>
                    <Alert severity="error">{error}</Alert>
                    <Box height={20} />
                  </>
                )}
                {success && (
                  <>
                    <Alert severity="success">{success}</Alert>
                    <Box height={20} />
                  </>
                )}
                <Stack spacing={2}>
                  <Box>
                    <FormLabel>Company Name</FormLabel>
                    <TextField
                      fullWidth
                      name="name"
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.name)}
                      helperText={formik.errors.name}
                      value={formik.values.name}
                    />
                  </Box>
                  <Box>
                    <FormLabel>Company Email</FormLabel>
                    <TextField
                      fullWidth
                      name="email"
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.email)}
                      helperText={formik.errors.email}
                      value={formik.values.email}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <FormLabel>Address Line 1</FormLabel>
                    <TextField
                      fullWidth
                      name="addressLine1"
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.addressLine1)}
                      helperText={formik.errors.addressLine1}
                      value={formik.values.addressLine1}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <FormLabel>Address Line 2</FormLabel>
                    <TextField
                      fullWidth
                      name="addressLine2"
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.addressLine2)}
                      helperText={formik.errors.addressLine2}
                      value={formik.values.addressLine2}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <FormLabel>City</FormLabel>
                    <TextField
                      fullWidth
                      name="city"
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.city)}
                      helperText={formik.errors.city}
                      value={formik.values.city}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <FormLabel>Country</FormLabel>
                    <CountrySelect
                      error={Boolean(formik.errors.country)}
                      helperText={formik.errors.country}
                      onChange={(val) => formik.setFieldValue("country", val)}
                      defaultValue={resData?.data?.data?.country}
                      value={formik.values.country}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <FormLabel>Phone Number</FormLabel>
                    <PhoneInput
                      error={Boolean(formik.errors.phone)}
                      helperText={formik.errors.phone}
                      onChange={(val) => formik.setFieldValue("phone", val)}
                      value={formik.values.phone}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <FormLabel>Default Leave Limit Per Year</FormLabel>
                    <TextField
                      fullWidth
                      name="leaveLimit"
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.leaveLimit)}
                      helperText={formik.errors.leaveLimit}
                      value={formik.values.leaveLimit}
                    />
                  </Box>
                </Stack>
              </CardContent>
            </Card>
            <Stack direction="row" justifyContent="end">
              <Button
                disabled={loadingSubmit}
                color="primary"
                variant="contained"
                type="submit"
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </form>
      </Box>
    </>
  );
}
