import { useEffect, useState } from "react";
import { DefaultSort } from "../../constants/sort";
import useSWR from "swr";
import Breadcrumb from "../../components/Breadcrumb";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Fab,
  IconButton,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import EnhancedTableToolbar from "../../components/table/EnhancedTableToolbar";
import CustomCheckbox from "../../components/forms/CustomCheckbox";
import { Link } from "react-router-dom";
import { AddRounded, EditRounded } from "@mui/icons-material";
import DeleteConfirmDialog from "../../components/dialogs/DeleteConfirmDialog";
import { DeleteTask, GetTasksByQuery } from "../../service/task";
import { DefaultSwrOptions } from "../../constants/constants";
import { useSelector } from "react-redux";
import { Roles } from "constants/role";

export default function Task() {
  const { role, profile } = useSelector((state) => state.profile);

  const [filter, setFilter] = useState({
    sort: DefaultSort.newest.value,
    limit: 20,
  });
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const {
    data: resData,
    isLoading: loading,
    mutate,
  } = useSWR(
    ["/api/task", filter],
    () => GetTasksByQuery(filter),
    
    DefaultSwrOptions
  );

  useEffect(() => {
    if (role === Roles.Employee) {
      setFilter({ ...filter, userId: profile?.id });
    }
  }, [role, profile]);

  const handleSelectItems = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((e) => e !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleSelectStatus = (value) => {
    setFilter({ status: value });
  };

  const handleDelete = () => {
    return DeleteTask(selectedItems.join(",")).then((res) => {
      if (res.status === 200) {
        mutate();
        setDeleteConfirm(false);
        setSelectedItems([]);
      }
    });
  };

  return (
    <>
      <Breadcrumb
        title="Tasks"
        items={[{ to: "/app", title: "Dashboard" }, { title: "Tasks" }]}
      />
      <Card>
        <CardContent>
          <EnhancedTableToolbar
            filter={filter}
            numSelected={selectedItems.length}
            handleChange={(newFilter) => setFilter({ ...filter, ...newFilter })}
            sortItems={DefaultSort}
            onDelete={() => setDeleteConfirm(true)}
            actions={
              <ButtonGroup variant="outlined" size="small">
                <Button
                  onClick={() => handleSelectStatus(null)}
                  variant={
                    filter?.status === null || filter?.status === undefined
                      ? "contained"
                      : "outlined"
                  }
                >
                  All
                </Button>
                <Button
                  sx={{ width: 100 }}
                  onClick={() => handleSelectStatus(true)}
                  variant={filter?.status ? "contained" : "outlined"}
                >
                  Active
                </Button>
                <Button
                  sx={{ width: 100 }}
                  onClick={() => handleSelectStatus(false)}
                  variant={filter?.status === false ? "contained" : "outlined"}
                >
                  Completed
                </Button>
              </ButtonGroup>
            }
          ></EnhancedTableToolbar>
          {loading || loading === undefined ? (
            <Stack alignItems="center">
              <Box height={20} />
              <CircularProgress size={32} />
            </Stack>
          ) : (
            <>
              <TableContainer>
                <Table sx={{ whiteSpace: "nowrap" }}>
                  <TableHead>
                    <TableRow>
                      {role === Roles.Admin && <TableCell />}
                      <TableCell>Task Name</TableCell>
                      <TableCell>Assigned To</TableCell>
                      <TableCell>Project</TableCell>
                      <TableCell>Progress</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell align="right">Option</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resData?.data?.data?.length > 0 ? (
                      resData?.data?.data?.map((row, i) => (
                        <TableRow key={i}>
                          {role === Roles.Admin && (
                            <TableCell padding="checkbox">
                              <CustomCheckbox
                                color="primary"
                                checked={selectedItems.includes(row._id)}
                                onChange={() => handleSelectItems(row._id)}
                              />
                            </TableCell>
                          )}
                          <TableCell>{row?.title}</TableCell>
                          <TableCell>{row?.user?.name ?? "-"}</TableCell>
                          <TableCell>{row?.project?.name ?? "-"}</TableCell>
                          <TableCell>{row?.progress}%</TableCell>
                          <TableCell>
                            <Chip
                              color={row?.status ? "success" : "primary"}
                              label={row?.status ? "Active" : "Completed"}
                              size="small"
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Link to={`/app/task/${row._id}/update`}>
                              <Tooltip title="Edit">
                                <IconButton>
                                  <EditRounded
                                    fontSize="small"
                                    sx={{ color: "text.secondary" }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          No Data
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Stack direction="row" paddingTop={3} justifyContent="end">
                <Pagination
                  color="secondary"
                  count={resData?.data?.pagination?.pages ?? 1}
                  page={parseInt(resData?.data?.pagination?.page)}
                  size="small"
                  onChange={(e, val) => setFilter({ ...filter, page: val })}
                />
              </Stack>
            </>
          )}
        </CardContent>
      </Card>

      {(role === Roles.Admin || role === Roles.SuperAdmin) && (
        <Link to={`/app/task/create`}>
          <Tooltip title="Add Data">
            <Fab
              color="primary"
              aria-label="add"
              sx={{ position: "fixed", right: "25px", bottom: "15px" }}
            >
              <AddRounded />
            </Fab>
          </Tooltip>
        </Link>
      )}

      <DeleteConfirmDialog
        open={deleteConfirm}
        onClose={() => setDeleteConfirm(false)}
        onSubmit={handleDelete}
      />
    </>
  );
}
