import ApiInstance from "../utils/instance";

export const GetUsersByQuery = (query) => {
  return ApiInstance.get("/hrms_user", { params: query });
};

export const GetUserById = (id) => {
  return ApiInstance.get(`/hrms_user/${id}`);
};

export const CreateUser = (params) => {
  return ApiInstance.post("/hrms_user", params);
};

export const UpdateUser = (id, params) => {
  return ApiInstance.put(`/hrms_user/${id}`, params);
};

export const DeleteUser = (ids) => {
  return ApiInstance.delete(`/hrms_user/${ids}`);
};
