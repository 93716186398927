export const StorageKey = {
  TOKEN: "token",
  USER: "user",
};

export const DefaultSwrOptions = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};



export const LeaveStatus = {
  pending: { name: "Pending", value: "pending" },
  approved: { name: "Approved", value: "approved" },
};

export const ExpenseStatus = {
  pending: { name: "Pending", value: "pending" },
  completed: { name: "Completed", value: "completed" },
};

// export const LeaveStatus = {
//   pending: { name: "Pending", value: "pending" },
//   approved: { name: "Approved", value: "approved" },
// };

// export const ExpenseStatus = {
//   pending: { name: "Pending", value: "pending" },
//   completed: { name: "Completed", value: "completed" },
// };
