import isThis from "lib/is-this";

export const Roles = {
  Admin: "Admin",
  SuperAdmin: "Super Admin",
  Employee : "employee"
};

export function isRole(user, role) {
  return isThis.isStringsEqual(user?.role, role);
}

export function isSuperAdmin(user) {
  return isThis.isStringsEqual(user?.role, Roles.SuperAdmin);
}
export function isAdmin(user) {
  return isThis.isStringsEqual(user?.role, Roles.Admin);
}


