import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  Stack,
  Typography,
} from "@mui/material";
import ProfileMenus from "../constants/profile";
import { Link, useNavigate } from "react-router-dom";
import ApiInstance from "utils/instance";
import { StorageKey } from "constants/constants";
import { GetItem, RemoveItem } from "utils/storage";

const Profile = () => {
  const navigate = useNavigate();
  const [anchorEl2, setAnchorEl2] = useState(null);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const logout = async () => {
    const user = await GetItem(StorageKey.USER);
    await ApiInstance.put("/users/delete-session", { id: user?.id });
    await RemoveItem(StorageKey.TOKEN);
    await RemoveItem(StorageKey.USER);
    window.location.href = "/";
  };

  const user = GetItem(StorageKey.USER);

  console.log({ user });

  return (
    <Box>
      <IconButton
        size="large"
        aria-label="show 11 new notifications"
        color="inherit"
        aria-controls="msgs-menu"
        aria-haspopup="true"
        sx={{
          ...(typeof anchorEl2 === "object" && {
            color: "primary.main",
          }),
        }}
        onClick={handleClick2}
      >
        <div
          style={{
            borderRadius: "50%",
            width: "50px",
            height: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#f1f1f1",
            fontSize: "20px",
          }}
        >
          {user?.fullname?.[0]}
        </div>
      </IconButton>
      <Menu
        id="msgs-menu"
        anchorEl={anchorEl2}
        keepMounted
        open={Boolean(anchorEl2)}
        onClose={handleClose2}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        sx={{
          "& .MuiMenu-paper": {
            width: "300px",
            p: 4,
          },
        }}
      >
        <Typography variant="h5">User Profile</Typography>
        <Stack direction="row" py={3} spacing={2} alignItems="center">
          <div
            style={{
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "#ccc",
            }}
          >
            {user?.fullname?.[0]}
          </div>
          {/* <Avatar
          
            src={"/images/profile.svg"}
            alt={"ProfileImg"}
            sx={{ width: 50, height: 50 }}
          /> */}
          <Box>
            <Typography
              variant="subtitle2"
              color="textPrimary"
              fontWeight={600}
            >
              {user?.fullname}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {user?.role}
            </Typography>
          </Box>
        </Stack>
        <Divider />
        <Box sx={{ py: 2, px: 0 }} className="hover-text-primary">
          <a
            style={{ color: "#000" }}
            href={`${process.env.REACT_APP_Invoice_Purchase_Portal_Redirect}?sessionId=${user?.sessionId}`}
            target="_invoice"
          >
            Invoice Purchase Portal
          </a>
        </Box>
        {/*
        {ProfileMenus.map(({ icon: Component, ...profile }) => (
          <Box key={profile.title}>
            <Box sx={{ py: 2, px: 0 }} className="hover-text-primary">
              <Link to={profile.href}>
                <Stack direction="row" spacing={2}>
                  <Box
                    width="45px"
                    height="45px"
                    bgcolor="primary.light"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Component
                      sx={{
                        width: 24,
                        height: 24,
                        borderRadius: 0,
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      fontWeight={600}
                      color="textPrimary"
                      className="text-hover"
                      noWrap
                      sx={{
                        width: "240px",
                      }}
                    >
                      {profile.title}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle2"
                      sx={{
                        width: "240px",
                      }}
                      noWrap
                    >
                      {profile.subtitle}
                    </Typography>
                  </Box>
                </Stack>
              </Link>
            </Box>
          </Box>
        ))} */}
        <Box mt={2}>
          <Button onClick={logout} variant="outlined" color="primary" fullWidth>
            Logout
          </Button>
        </Box>
      </Menu>
    </Box>
  );
};

export default Profile;
